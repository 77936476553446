import axios from 'axios'
import React from 'react'
import styled from 'styled-components'
import LoadingSpinner from '../../components/LoadingSpinner'

const HowToGetInvolved = styled.div`
border-top: 1px solid #000;
border-left: 1px solid #000;
border-right: 1px solid #000;
margin: 20px 0;

.four-square-heading {
  text-align: center;
  text-transform: uppercase;
}

.top-artist-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.cell-1-row-1, .cell-2-row-1, .cell-1-row-2, .cell-2-row-2 {
  padding: 10px;
  border-bottom: 1px solid #000;
}

@media screen and (min-width: ${props => props.theme.responsive.medium}) {
  .top-artist-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .cell-1-row-1, .cell-2-row-1 {
    border-bottom: 1px solid #000;
  }

  .cell-2-row-1, .cell-2-row-2 {
    border-left: 1px solid #000;
  }
}
`
const { GATSBY_GOOGLE_SHEETS_URL } = process.env

const Foundation = () => {

  const handleSubmit = (event) => {
    event.preventDefault()
    let formError = ''

    if (!fullName || !email || !event.target[2].value) {
      formError = 'Name, email and area of interest cannot be empty. Please complete the form below.'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      formError = 'Please enter a valid email address below.'
    }

    if (!formError) {
      setSubmissionPending(true)

      const form = new FormData()
      form.append('Name', fullName)
      form.append('Email', email)
      form.append('Interest', event.target[2].value)
      form.append('Comments', comments)

      axios.post(GATSBY_GOOGLE_SHEETS_URL, form)
        .then(function (response) {
          setSubmissionPending(false)
          setShowForm(false)
          setSuccess('Your request was successfully processed!')
        }).catch(function (error) {
          setSubmissionPending(false)
          setError('There was an error processing your request')
          //console.log(error)
        })
    } else {
      setError(formError)
    }
  }

  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState('')
  const [fullName, setFullName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [comments, setComments] = React.useState('')
  const [submissionPending, setSubmissionPending] = React.useState(false)
  const [showForm, setShowForm] = React.useState(true)

  const handleFullName = (event) => {
    setFullName(event.target.value)
  }

  const handleEmail = (event) => {
    setEmail(event.target.value)
  }

  const handleComments = (event) => {
    setComments(event.target.value)
  }

  return (
    <>
      <div>
        <h2 className="section-title">Seint Gallery Foundation</h2>
        <p>
          As a Seint Artist, you have the unique opportunity to partner with Seint through our non-profit foundations,{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://loveisneverwasted.com/">Love is Never Wasted</a> and{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://angelslandingfoundation.org/">Angels Landing</a>.
          We are committed to educating and encouraging communities and families to open their hearts and homes to youth in care and after care.
          We are honored to have our Artists working alongside us as we seek to serve and share, making a real difference in the foster care community.
        </p>
        <h2 className="section-title">How to Get Involved</h2>
        <HowToGetInvolved>
          <div className="top-artist-grid">
            <div className="cell-1-row-1" key="key1">
              <h4 className="four-square-heading">Eyeshadows</h4>
              Since the beginning of its journey, Seint has raised more than $1.6M through the sales of two of Seint’s eyeshadows: Mi Hija and Stay Golden.
              We encourage Artists to continue to sell Mi Hija and Stay Golden in an effort to raise awareness and support children and youth in foster care.
            </div>
            <div className="cell-2-row-1" key="key2">
              <h4 className="four-square-heading">Follow Us on Social Media</h4>
              If you haven’t already, we encourage you to follow, like, and share our{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/loveneverwasted/">Love is Never Wasted</a> and{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/angels.landingfoundation/">Angel’s Landing</a>{' '}
              social media posts.
              Your voice is powerful and can help bring awareness to the efforts we are making in the foster community; or visit our foundation websites
              (linked above) to learn more about how making financial contributions to the foundations can help impact a young person’s life.
            </div>
            <div className="cell-1-row-2" key="key3">
              <h4 className="four-square-heading">Foundation Activities</h4>
              Three times a year we will select Seint Artists to host foster care focused activities in their communities to encourage involvement with foster care
              and to continue our journey of raising awareness. If you are interested in hosting one of these activities or want to know more ways you can help,
              fill out the form below and we will get in touch with you. We believe that we all have the power to inspire and heal others, and we hope that you
              will join us in supporting the efforts of our foster care programs.
            </div>
            <div className="cell-2-row-2" key="key4">
              <h4 className="four-square-heading">Reunions</h4>
              At each Artist Reunion, we partner with a foster group local to our host city. We have a charity dinner that Artists pay to attend — all proceeds
              from the dinner, plus revenue from a special sales promotion that focuses around foster care, is donated to the local group we’ve partnered with.
              To see which partnerships we’ve done in the past, visit the links below:
              <br /><br />
              <a style={{ textTransform: 'uppercase' }} href="https://www.seintgallery.com/news/wildflower-foundation-partnership/">Boysville</a>
              <br /><br />
              <a style={{ textTransform: 'uppercase' }} href="https://www.seintgallery.com/news/endless-summer-linw-just-in-time/">Just In Time</a>
            </div>
          </div>
        </HowToGetInvolved>
        <p>
          By being part of Seint, we hope you'll find ways to get involved in foster care in your communities. If you are interested in becoming
          more involved in Seint’s nonprofits or learning more, please fill out the form below!
        </p>
        <h2 className="section-title">How We Can Support You</h2>
        <p>
          Seint is committed to recognizing and supporting Artists who are making a difference in the lives of children in foster care. We know the foster care
          journey can be full of ups and downs and we want to be with you every step of the way.  Our goal with the Love Is Never Wasted community is for Artists
          involved in foster care to feel appreciated and fully supported as we all work to share love with those who need it most.
        </p>
        <p>
          If you’re already involved in foster care, we want to learn more about you and your story. If you’ve had a recent status change such as becoming
          licensed, getting a foster placement, or finalizing an adoption, please fill out the form below so we can celebrate the milestones in your
          family’s life.
        </p>
        <p>Please fill out the form below and we will be in touch.</p>
        <p style={{ textAlign: 'center', color: '#008000' }}>{success}</p>
        {showForm === true && (
          <div style={{ textAlign: 'center' }}>
            <form onSubmit={handleSubmit}>
              <div style={{ textAlign: 'center', color: '#f00' }}>{error}</div>
              <div style={{ padding: '10px 0' }}>
                <input placeholder="Your Name" style={{ width: '80%' }} type="text" id="name" name="fullName" value={fullName} onChange={handleFullName} />
              </div>
              <div style={{ padding: '10px 0' }}>
                <input placeholder="Your Email" style={{ width: '80%' }} type="text" id="email" name="email" value={email} onChange={handleEmail} />
              </div>
              <div style={{ padding: '10px 0' }}>
                <select style={{ WebkitAppearance: 'listbox', width: '80%' }} id="interest" name="interest">
                  <option value="">Choose an area of interest</option>
                  <option value="Host Foster Care Activity">Host Foster Care Activity</option>
                  <option value="Foster Parenting">Foster Parenting</option>
                  <option value="Learn More">Learn More</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div style={{ padding: '10px 0' }}>
                <textarea style={{ width: '80%' }} placeholder="Add any comments here" id="comments" name="comments" rows="4" cols="50" value={comments} onChange={handleComments}></textarea>
              </div>
              <div style={{ padding: '10px 0 30px' }}>
                <button className="btn--submit" disabled={submissionPending} type="submit">Submit</button>
              </div>
              {submissionPending === true && (
                <LoadingSpinner
                  style={{
                    position: 'absolute',
                    top: '80%',
                    left: '50%',
                    transform: 'translate3d(-50%, -20%, 0)',
                  }}
                />
              )}
            </form>
          </div>
        )}
        <p>
          Feel free to contact us with any questions or for ways to get involved with our non-profits:
          <br />
          <br />
          <a
            href="mailto:foundation@seintofficial.com"
            target="_blank"
            rel="noopener noreferrer"
          >foundation@seintofficial.com</a>.
        </p>
      </div >
    </>
  )
}

export default Foundation
